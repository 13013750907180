import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

type Props = {
  data: any;
  location: string;
};

function NotFoundPage({ location, data }: Props) {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={`404: Not Found: ${siteTitle}`}>
      <h1>Not Found</h1>
      <p>A page that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
